import React from 'react';

const Pagination = () => {
    return (
        <div className="pagination">
        <button>1</button>
        <button>2</button>
        <button>3</button>
        <button>4</button>
        <button>5</button>
        </div>
    );
};

export default Pagination;
