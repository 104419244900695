import React from 'react';

const ButtonsSet = ({ activeButton, handleButtonClick }) => {
    return (
        <div className='array-of-buttons'>
        <button
            style={{ marginLeft: '5px', backgroundColor: activeButton === 'Teams' ? 'white' : 'transparent' }}
            onClick={() => handleButtonClick('Teams', null)}
        >
            Teams
        </button>
        <button
            style={{ backgroundColor: activeButton === 'Arguments' ? 'white' : 'transparent' }}
            onClick={() => handleButtonClick('Arguments', 0)}
        >
            Arguments
        </button>
        <button
            style={{ backgroundColor: activeButton === 'Comments' ? 'white' : 'transparent' }}
            onClick={() => handleButtonClick('Comments', 1)}
        >
            Comments
        </button>
        <button
            style={{ backgroundColor: activeButton === 'Fact Checks' ? 'white' : 'transparent' }}
            onClick={() => handleButtonClick('Fact Checks', 2)}
        >
            Fact Checks
        </button>
        <button
            style={{ marginRight: '5px', backgroundColor: activeButton === 'Debate Structure' ? 'white' : 'transparent' }}
            onClick={() => handleButtonClick('Debate Structure', null)}
        >
            Debate Structure
        </button>
        </div>
    );
};

export default ButtonsSet;
