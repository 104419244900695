import { useState } from "react";
import axios from "axios"; // Using axios for HTTP requests
import "./Register.css";
import { motion } from "framer-motion"; // Import framer-motion
import { useNavigate } from "react-router-dom";
import config from "../config";
import { Link } from "react-router-dom";

export default function Register() {
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Make the POST request to your Django server without a token
      const response = await axios.post(`${config.apiUrl}/register/`, {
        fullname,
        username,
        email,
        password,
        confirm_password: password, // Assuming the confirm password is the same for now
      });

      // If the registration is successful
      if (response.status === 201) {
        navigate("/"); // Redirect to home or another page after successful registration
      }
    } catch (error) {
      console.error("Error during registration:", error); // Log the error details
      if (error.response) {
        console.log("Server response data:", error.response.data); // Log response data from the server
        setError(
          error.response.data.detail ||
            "Error during registration. Please try again."
        );
      } else {
        setError("An unexpected error occurred. Please try again later.");
      }
    }
  };

  return (
    <motion.div
      className="register-container"
      initial={{ opacity: 0, y: -50 }} // Initial state before animation starts
      animate={{ opacity: 1, y: 0 }} // Final state after animation
      transition={{ duration: 0.5, ease: "easeOut" }} // Animation duration and easing
    >
      <h1>Welcome to RgueAI</h1>
      <motion.form
        className="register-form"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }} // Delayed to make animation smoother
        onSubmit={handleSubmit}
      >
        <div className="form-group">
          <label htmlFor="fullname" className="form-label">
            Full Name
          </label>
          <motion.input
            id="fullname"
            type="text"
            placeholder="Your fullname"
            className="form-input"
            whileFocus={{
              borderColor: "#007bff",
              boxShadow: "0 0 8px rgba(0, 123, 255, 0.2)",
            }}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <motion.input
            id="email"
            type="email"
            placeholder="Your emailaddress"
            className="form-input"
            whileFocus={{
              borderColor: "#007bff",
              boxShadow: "0 0 8px rgba(0, 123, 255, 0.2)",
            }}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="username" className="form-label">
            Username
          </label>
          <motion.input
            id="username"
            type="text"
            placeholder="Choose a username"
            className="form-input"
            value={username} // Bind value to the state
            onChange={(e) => setUserName(e.target.value)} // Update state on change
            whileFocus={{
              borderColor: "#007bff",
              boxShadow: "0 0 8px rgba(0, 123, 255, 0.2)",
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <motion.input
            id="password"
            type="password"
            placeholder="Create a password"
            className="form-input"
            whileFocus={{
              borderColor: "#007bff",
              boxShadow: "0 0 8px rgba(0, 123, 255, 0.2)",
            }}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <motion.button
          type="submit"
          className="register-button"
          whileHover={{ scale: 1.05 }} // Slightly enlarges on hover
          whileTap={{ scale: 0.95 }} // Shrinks a bit when clicked
        >
          Register
        </motion.button>
      </motion.form>
      <p className="terms-text">
        By signing up, you agree to our <Link to="#">Terms of Service</Link> and{" "}
        <Link to="#">Privacy Policy</Link>
      </p>
      <p className="login-text">
        <Link to="/login">Already have an account?</Link>
      </p>
      <motion.div
        className="additional-actions"
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        <motion.button
          type="button"
          className="login-button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => {
            navigate("/login");
          }}
        >
          Login
        </motion.button>
        <motion.a
          href="#"
          className="forgot-password-link"
          whileHover={{ color: "#0056b3", textDecoration: "underline" }} // Changes color and underline on hover
        >
          Forgot your password?
        </motion.a>
      </motion.div>
    </motion.div>
  );
}
