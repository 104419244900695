import React, { useEffect, useState } from 'react';
import axios from 'axios';  // For making API calls
import './DebateMeeting.css';  // Optional: CSS for PlugNMeet container
import { useParams } from 'react-router-dom';  // For accessing debate ID from the URL
import config from '../config';  // Assuming you have an API config file

const DebateMeeting = ({ token }) => {
    const [joinToken, setJoinToken] = useState(null);  // Store the PlugNMeet join token
    const [roomName, setRoomName] = useState(null);  // Store the PlugNMeet room name
    const { id } = useParams();  // Get debate ID from URL parameters

    useEffect(() => {
        // Fetch PlugNMeet meeting details (join token and room name) from the backend
        const fetchMeetingDetails = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}/debates/${id}/join_meeting/`, {
                    headers: {
                        Authorization: `Bearer ${token}`  // Pass the app authentication token
                    }
                });

                if (response.data) {
                    setJoinToken(response.data.token);  // Set the PlugNMeet join token
                } else {
                    console.error('No meeting details returned');
                }
            } catch (error) {
                console.error('Error fetching meeting details:', error);
            }
        };

        fetchMeetingDetails();
    }, [id, token]);

    useEffect(() => {
        // Initialize the PlugNMeet meeting only when we have the join token and room name
        if (joinToken) {
            initPlugNMeetMeeting(joinToken, id);
        }

        // Cleanup function on unmount
        return () => {
            const meetingContainer = document.getElementById('plugnmeet-container');
            if (meetingContainer) {
                meetingContainer.innerHTML = '';  // Clear the meeting container on unmount
            }
        };
    }, [joinToken, roomName]);

    const initPlugNMeetMeeting = (token, roomName) => {
        // Use an iframe to embed the PlugNMeet client or JavaScript API
        const meetingUrl = `${config.plugnmeetUrl}?access_token=${token}&room_name=${roomName}`;  // Construct the URL

        // Create an iframe element to load PlugNMeet meeting
        const iframe = document.createElement('iframe');
        iframe.src = meetingUrl;  // Use the generated meeting URL
        iframe.style.width = '100%';  // Full width
        iframe.style.height = '90vh';  // Full height of the viewport
        iframe.style.border = 'none';  // No borders
        iframe.allow = "camera; microphone; fullscreen";

        const container = document.getElementById('plugnmeet-container');
        container.appendChild(iframe);  // Append the iframe to the container
    };

    return (
        <div id="plugnmeet-container"></div>  // This is where the PlugNMeet meeting will be embedded
    );
};

export default DebateMeeting;
