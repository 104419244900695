import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { defaultProfilePicture } from "../../data/static";
import { defaultImage } from "../../data/static";
const DebateCard = ({ debate }) => {
    

    const navigate = useNavigate(); // Initialize the navigate function
    const [isImageLoading, setIsImageLoading] = useState(true); // Track loading state of image

    const hasParticipants = debate.role_assignments.some(roleAssign => roleAssign.user && roleAssign.user.profile);

    // Handle image click to navigate to the debate details page
    const handleImageClick = () => {
        navigate(`/debate/${debate.id}`);
    };

    // Handle image load to stop showing spinner
    const handleImageLoad = () => {
        setIsImageLoading(false);
    };

    return (
    <>

        <div className="debate-card">
            <img
                src={debate.image || defaultImage}
                alt="Debate"
                className="debate-image"
                onClick={handleImageClick}
                onLoad={handleImageLoad} // Fires when the image has loaded
                style={{
                display: isImageLoading ? "none" : "block",
                cursor: "pointer",
                }} // Hide the image until loaded
            />

            <div className="debater-topic">
                <h5>{debate.title || "This Debate has no title"}</h5>
            </div>

            <div className="debate-participants">
                {hasParticipants ? (
                debate.role_assignments.map(
                    (roleAssign, index) =>
                    roleAssign.user &&
                    roleAssign.user.profile && (
                        <img
                        src={
                            roleAssign.user.profile.profile_picture ||
                            defaultProfilePicture
                        }
                        alt={`${roleAssign.user.username}'s profile`}
                        className="participant-image"
                        key={index}
                        />
                    )
                )
                ) : (
                <p style={{fontFamily:'Manrope'}}>No participants yet</p>
                )}
            </div>

            <p style={{margin:'0', color:'#4F7396'}}>{debate.status}</p>
            </div>
        </>
    );
};

export default DebateCard;
