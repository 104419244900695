import React, { useState, useEffect } from "react";
import "./DebateRoles.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openDialog, setUserRole } from "../../redux/userSlice";
import { setRoleTemplates } from "../../redux/roleTemplatesSlice"; // Action to set role templates in Redux
import { setRoleAssignments } from "../../redux/roleAssignmentsSlice"; // Action to set role assignments in Redux

const DebateRoles = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    // Fetch roles and assignments from Redux state
    const roleTemplates = useSelector((state) => state.roleTemplates.roleTemplates);
    const currentUserRole = useSelector((state) => state.user.role);
    const roleAssignments = useSelector((state) => state.roleAssignments.roleAssignments);

    const [selectedRole, setSelectedRole] = useState(currentUserRole || localStorage.getItem('userRole')); // Initialize selectedRole from Redux or localStorage
    const [notification, setNotification] = useState(""); // State to manage notification messages

    // Load data from localStorage when the component mounts
    useEffect(() => {
        const storedRoleTemplates = localStorage.getItem('roleTemplates');
        const storedRoleAssignments = localStorage.getItem('roleAssignments');
        const storedUserRole = localStorage.getItem('userRole');
        
        // Load currentUserRole from localStorage if not available in Redux
        if (!currentUserRole && storedUserRole) {
            dispatch(setUserRole(storedUserRole));
        }

        // Load roleTemplates from localStorage if available
        if (storedRoleTemplates && roleTemplates.length === 0) {
            dispatch(setRoleTemplates(JSON.parse(storedRoleTemplates)));
        }

        // Load roleAssignments from localStorage if available
        if (storedRoleAssignments && roleAssignments.length === 0) {
            dispatch(setRoleAssignments(JSON.parse(storedRoleAssignments)));
        }
    }, [dispatch, currentUserRole, roleTemplates.length, roleAssignments.length]);

    console.log("DEBATE ROLES DEBUG");
    console.log("Role Templates:\n", roleTemplates);
    console.log("Role Assignments:\n", roleAssignments);
    console.log("DEBATE ROLES DEBUG");

    // Auto-dismiss notification after a few seconds
    useEffect(() => {
        if (notification) {
            const timer = setTimeout(() => setNotification(""), 3000); // Dismiss after 3 seconds
            return () => clearTimeout(timer); // Cleanup timer
        }
    }, [notification]);

    const handleRoleClick = (newRole) => {
        console.log("Current User Role:", currentUserRole);
        console.log("New Role:", newRole);
        if (currentUserRole && currentUserRole !== newRole) {
            setNotification("You cannot change your role once it is assigned.");
        } else {
            setSelectedRole(newRole);
            dispatch(openDialog(newRole));
            navigate(-1);
        }
    };

    const isRoleAssigned = (roleName) => {
        return roleAssignments.some(
            (assignment) =>
                assignment.role_template.name === roleName && assignment.user !== null
        );
    };

    return (
        <div className="roles-container">
            {/* Notification Bar */}
            {notification && <div className="notification-bar">{notification}</div>}
            
            <h1 className="par-h1">Participate in a Debate</h1>
            <h2 className="role-h2">Role</h2>
            <section className="roles-section">
                {roleTemplates.map((role, index) => {
                    const roleAssigned = isRoleAssigned(role.name);

                    return (
                        <div key={index} className="role-item">
                            <div>
                                <h4 style={{ margin: "0", fontSize: "medium" }}>{role.name}</h4>
                                <p style={{ margin: "0", color: "#4F7396" }}>
                                    {role.description}
                                </p>
                            </div>
                            <button
                                onClick={() => handleRoleClick(role.name)}
                                disabled={selectedRole === role.name || roleAssigned}
                                className={
                                    selectedRole === role.name || roleAssigned
                                        ? "disabled-button"
                                        : ""
                                }
                            >
                                Assign
                            </button>
                        </div>
                    );
                })}
            </section>
        </div>
    );
};

export default DebateRoles;
