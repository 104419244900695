import { createSlice } from "@reduxjs/toolkit";

// Initial state for role assignments
const initialState = {
  roleAssignments: [], // Array to hold role assignments
};

// Create the role assignments slice
const roleAssignmentsSlice = createSlice({
  name: "roleAssignments",
  initialState,
  reducers: {
    setRoleAssignments(state, action) {
      // Set all role assignments
      state.roleAssignments = action.payload;
    },
    addRoleAssignment(state, action) {
      // Add a new role assignment to the state
      state.roleAssignments.push(action.payload);
    },
    updateRoleAssignment(state, action) {
      // Update an existing role assignment by user ID or role ID
      const { userId, updatedAssignment } = action.payload;
      const index = state.roleAssignments.findIndex(
        (assignment) => assignment.user && assignment.user.id === userId
      );
      if (index !== -1) {
        state.roleAssignments[index] = updatedAssignment;
      }
    },
    removeRoleAssignment(state, action) {
      // Remove a role assignment by user ID
      state.roleAssignments = state.roleAssignments.filter(
        (assignment) => assignment.user && assignment.user.id !== action.payload
      );
    },
  },
});

// Export the actions generated by createSlice
export const {
  setRoleAssignments,
  addRoleAssignment,
  updateRoleAssignment,
  removeRoleAssignment,
} = roleAssignmentsSlice.actions;

// Export the reducer to add to the store
export default roleAssignmentsSlice.reducer;
