// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#019863',
    },
    secondary: {
      main: '#A18249',
    },
    background: {
      default: '#FFFFFF',
      paper: '#F4EFE6',
    },
    text: {
      primary: '#1C160C',
      secondary: '#A18249',
    },
  },
  typography: {
    fontFamily: '"Plus Jakarta Sans", "Noto Sans", sans-serif',
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#1C160C',
            borderBottom: '2px solid #019863',
          },
          '&:hover': {
            color: '#000000',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

export default theme;
