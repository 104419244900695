import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Avatar, Typography, Box } from '@mui/material';
import config from '../config';

const ProfileUpdate = ({ token }) => {
    const [profile, setProfile] = useState({
        bio: '',
        interests: '',
        profile_picture: ''
    });
    const [newPicture, setNewPicture] = useState(null);

    useEffect(() => {
        axios.get(`${config.apiUrl}/profiles/me/`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => setProfile(response.data))
        .catch(error => console.error('Error fetching profile:', error));
    }, [token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        setNewPicture(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('bio', profile.bio);
        formData.append('interests', profile.interests);
        if (newPicture) {
            formData.append('profile_picture', newPicture);
        }

        try {
            const response = await axios.put(`${config.apiUrl}/profiles/update_me/`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            setProfile(response.data);
            console.log('Profile updated:', response.data);
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            marginTop={4}
        >
            <Typography variant="h4">Update Profile</Typography>
            <Avatar 
                alt="Profile Picture" 
                src={profile.profile_picture || ''} 
                sx={{ width: 120, height: 120, margin: 2 }}
            />
            <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: 400 }}>
                <TextField
                    fullWidth
                    label="Biography"
                    name="bio"
                    value={profile.bio}
                    onChange={handleChange}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Interests"
                    name="interests"
                    value={profile.interests}
                    onChange={handleChange}
                    margin="normal"
                />
                <input
                    accept="image/*"
                    type="file"
                    onChange={handleFileChange}
                    style={{ margin: '20px 0' }}
                />
                <Button type="submit" variant="contained" color="primary" fullWidth>
                    Save
                </Button>
            </form>
        </Box>
    );
};

export default ProfileUpdate;
