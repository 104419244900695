import React from "react";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";
import { getDebateFormat } from "../../data/static";
import { defaultProfilePicture } from "../../data/static";
const DebateBoard = ({debates}) => {


    return (
        <div className="debate-list">
        <section>
            <h2>Join as a Debater</h2>
            <table>
            <thead>
                <tr>
                <th>Motion</th>
                <th>Type</th>
                <th>Status</th>
                <th>Actions</th>
                <th>Debaters</th>
                </tr>
            </thead>
            <tbody>
            {debates
                .filter(debate => 
                debate.role_assignments.some(roleAssign => roleAssign.user === null) // Filter debates where at least one user is null
                )
                .map((debate, index) => (
                <tr key={index}>
                    <td>{debate.motion || 'This debate has no motion'}</td>
                    <td>{getDebateFormat(debate.format.description)}/</td>
                    <td>{debate.status || 'This debate has no status'}</td>
                    <td>
                    <Link to={`/debate/${debate.id}`} className="action-button">Go to debate</Link>
                    </td>
                    <td>
                    <div className="debate-participants">
                        {debate.role_assignments.some(roleAssign => roleAssign.user && roleAssign.user.profile)?(
                        debate.role_assignments.map(
                        (roleAssign, index) =>
                        roleAssign.user &&
                        roleAssign.user.profile && (
                            <img
                            src={
                                roleAssign.user.profile.profile_picture ||
                                defaultProfilePicture
                            }
                            alt={`${roleAssign.user.username}'s profile`}
                            className="participant-image"
                            key={index}
                            />
                        )
                    )
                ) : (
                <p>No participants yet</p>
                )}
                    </div>
                    </td>
                </tr>
                ))}
            </tbody>

            </table>
        </section>

        <section>
            <h2>Upcoming Debates</h2>
            <table>
            <thead>
                <tr>
                <th>Motion</th>
                <th>Type</th>
                <th>Status</th>
                <th>Actions</th>
                <th>Debaters</th>
                </tr>
            </thead>
            <tbody>
                {debates.map((debate, index) => (
                <tr key={index}>
                    <td>
                    {debate.motion || 'This debate has no motion'}
                    </td>
                    <td>{getDebateFormat(debate.format.description)}</td>
                    <td>{debate.status}</td>
                    <td>
                    <Link to={`/debate/${debate.id}`} className="action-button">Go to debate</Link>
                    </td>
                    <td>
                    <div className="debater-avatars">
                        {debate.role_assignments.some(roleAssign => roleAssign.user && roleAssign.user.profile) ? (
                            debate.role_assignments.map(
                                (roleAssign, index) =>
                                roleAssign.user &&
                                roleAssign.user.profile && (
                                    <img
                                    src={
                                        roleAssign.user.profile.profile_picture ||
                                        defaultProfilePicture
                                    }
                                    alt={`${roleAssign.user.username}'s profile`}
                                    className="participant-image"
                                    key={index}
                                    />
                                )
                            )
                            ) : (
                            <p>No participants yet</p>
                            )}
                    </div>
                    </td>
                </tr>
                ))}
            </tbody>
            </table>
        </section>

        <Pagination />
        </div>
    );
    };

    export default DebateBoard;
