// mockDebates.js
export const mockDebate1 = {
    "name": "Is AGI Possible?",
    "party": "neutral",
    "children": [
      {
        "name": "Yes, AGI is achievable",
        "party": "pro",
        "children": [
          {
            "name": "Computing power is increasing exponentially",
            "party": "pro",
            "children": [
              {
                "name": "Moore's Law continues to hold",
                "party": "pro"
              },
              {
                "name": "Quantum computing offers new potentials",
                "party": "pro"
              }
            ]
          },
          {
            "name": "Human cognition is better understood",
            "party": "pro",
            "children": [
              {
                "name": "Advances in neuroscience help mimic brain structures",
                "party": "pro"
              },
              {
                "name": "AI is now able to simulate human learning",
                "party": "pro"
              }
            ]
          },
          {
            "name": "AI can self-improve",
            "party": "pro",
            "children": [
              {
                "name": "Machine learning algorithms can iterate on themselves",
                "party": "pro"
              },
              {
                "name": "Recursive self-improvement could lead to AGI",
                "party": "pro"
              }
            ]
          }
        ]
      },
      {
        "name": "No, AGI is unlikely",
        "party": "con",
        "children": [
          {
            "name": "There are fundamental limits to AI",
            "party": "con",
            "children": [
              {
                "name": "AI cannot replicate consciousness",
                "party": "con"
              },
              {
                "name": "True understanding is impossible for machines",
                "party": "con"
              }
            ]
          },
          {
            "name": "Ethical concerns prevent true AGI",
            "party": "con",
            "children": [
              {
                "name": "Governments will limit AGI development",
                "party": "con"
              },
              {
                "name": "Corporate interests prioritize profit over AGI goals",
                "party": "con"
              }
            ]
          },
          {
            "name": "Resource constraints will slow development",
            "party": "con",
            "children": [
              {
                "name": "Computing power is expensive and limited",
                "party": "con"
              },
              {
                "name": "Training data is finite and biased",
                "party": "con"
              }
            ]
          }
        ]
      }
    ]
  }
  
  
  export const mockDebate2 = {
    "name": "Should AI be trusted in decision making?",
    "party": "neutral",
    "children": [
      {
        "name": "Yes, AI can enhance decision making",
        "party": "pro",
        "children": [
          {
            "name": "AI processes vast amounts of data quickly",
            "party": "pro",
            "children": [
              {
                "name": "Human decision making is slow and biased",
                "party": "pro"
              },
              {
                "name": "Bias in AI algorithms can lead to discrimination",
                "party": "con"
              }
            ]
          },
          {
            "name": "AI can reduce human errors",
            "party": "pro",
            "children": [
              {
                "name": "AI may overfit to data and make critical errors",
                "party": "con",
                "children": [
                  {
                    "name": "Errors in AI can have catastrophic consequences",
                    "party": "con"
                  },
                  {
                    "name": "Humans should always have the final say",
                    "party": "con"
                  }
                ]
              },
              {
                "name": "Humans are prone to fatigue, AI isn’t",
                "party": "pro"
              }
            ]
          },
          {
            "name": "AI can process more data than a human ever could",
            "party": "pro"
          }
        ]
      },
      {
        "name": "No, AI cannot be trusted in decision making",
        "party": "con",
        "children": [
          {
            "name": "AI lacks emotional intelligence and context",
            "party": "con",
            "children": [
              {
                "name": "Humans understand moral consequences better",
                "party": "pro",
                "children": [
                  {
                    "name": "Moral choices are subjective and differ across cultures",
                    "party": "con"
                  }
                ]
              },
              {
                "name": "AI has no empathy in decisions like human interactions",
                "party": "con"
              }
            ]
          },
          {
            "name": "AI is prone to biases in training data",
            "party": "con",
            "children": [
              {
                "name": "Bias in data sets can worsen over time",
                "party": "con"
              },
              {
                "name": "However, AI can learn to self-correct",
                "party": "pro"
              }
            ]
          },
          {
            "name": "AI decision making is opaque and hard to interpret",
            "party": "con"
          }
        ]
      },
      {
        "name": "AI can assist human decision making, but not replace it",
        "party": "neutral",
        "children": [
          {
            "name": "Humans can leverage AI’s data-processing abilities",
            "party": "pro",
            "children": [
              {
                "name": "This combination reduces bias and improves speed",
                "party": "pro"
              },
              {
                "name": "AI’s processing should complement human intuition",
                "party": "neutral"
              }
            ]
          },
          {
            "name": "Humans must always be accountable for decisions",
            "party": "con",
            "children": [
              {
                "name": "AI cannot take responsibility for wrong decisions",
                "party": "con"
              },
              {
                "name": "AI tools must have human oversight",
                "party": "con"
              }
            ]
          }
        ]
      }
    ]
  }
  
  
  
  export const mockDebate3 = {
    "name": "Should Universal Basic Income Be Implemented?",
    "party": "neutral",
    "children": [
      {
        "name": "Yes, UBI should be implemented",
        "party": "pro",
        "children": [
          {
            "name": "UBI reduces poverty",
            "party": "pro",
            "children": [
              {
                "name": "Guaranteed income ensures basic needs are met",
                "party": "pro"
              },
              {
                "name": "People can escape the poverty cycle",
                "party": "pro"
              }
            ]
          },
          {
            "name": "UBI promotes innovation",
            "party": "pro",
            "children": [
              {
                "name": "With financial security, people can take more risks",
                "party": "pro"
              },
              {
                "name": "More people can start businesses",
                "party": "pro"
              }
            ]
          },
          {
            "name": "UBI addresses job automation",
            "party": "pro",
            "children": [
              {
                "name": "Jobs are disappearing due to automation",
                "party": "pro"
              },
              {
                "name": "UBI provides a safety net in an automated economy",
                "party": "pro"
              }
            ]
          }
        ]
      },
      {
        "name": "No, UBI should not be implemented",
        "party": "con",
        "children": [
          {
            "name": "UBI is too expensive",
            "party": "con",
            "children": [
              {
                "name": "Governments cannot afford to pay everyone",
                "party": "con"
              },
              {
                "name": "Taxes would increase significantly",
                "party": "con"
              }
            ]
          },
          {
            "name": "UBI could disincentivize work",
            "party": "con",
            "children": [
              {
                "name": "People may rely on UBI and not seek jobs",
                "party": "con"
              },
              {
                "name": "It could lead to a decline in productivity",
                "party": "con"
              }
            ]
          },
          {
            "name": "Better alternatives to UBI exist",
            "party": "con",
            "children": [
              {
                "name": "Targeted welfare programs are more effective",
                "party": "con"
              },
              {
                "name": "Job retraining programs address the root causes",
                "party": "con"
              }
            ]
          }
        ]
      }
    ]
  }
  
  