import React from "react";
import Hero from "./landingPageComponents/Hero";
import "./LandingPage.css";
import AboutUs from "./landingPageComponents/AboutUs";
import GetStarted from "./landingPageComponents/GetStarted";
import Statistics from "./landingPageComponents/Statistics";
import { motion } from "framer-motion";

const LandingPage = ({token}) => {


    return (
      <>
        <Hero />
        <AboutUs />
        <Statistics/>
        <GetStarted token={token}/>
      </>
    );
      
}

export default LandingPage;
