import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import Tree from 'react-d3-tree';
import { mockDebate1, mockDebate2, mockDebate3 } from '../mockData';
import './ArgumentTypology.css'; // Import custom CSS for styling

const ArgumentTypology = () => {
  const [view, setView] = useState('sunburst'); // 'sunburst' or 'ancestorStack'
  const [debateData, setDebateData] = useState(mockDebate1); // Default to the first debate
  const [selectedNode, setSelectedNode] = useState(null); // For detailed view on click in Sunburst
  const svgRef = useRef(null); // Reference to the SVG for the sunburst
  const containerRef = useRef(null); // Reference for the fullscreen container

  // Color palette for parties
  const getPartyColor = (party) => {
    switch (party) {
      case 'pro':
        return '#66c2a5'; // Light green
      case 'con':
        return '#fc8d62'; // Light red
      default:
        return '#8da0cb'; // Light blue
    }
  };

  // Render Sunburst chart using D3.js with hover and click interactions
  useEffect(() => {
    if (view === 'sunburst') {
      const svg = d3.select(svgRef.current);
      svg.selectAll("*").remove(); // Clear the previous drawing

      const width = 500;
      const radius = width / 2;

      const partition = d3.partition()
        .size([2 * Math.PI, radius]);

      const root = d3.hierarchy(debateData)
        .sum(d => 1);

      partition(root);

      const arc = d3.arc()
        .startAngle(d => d.x0)
        .endAngle(d => d.x1)
        .innerRadius(d => d.y0)
        .outerRadius(d => d.y1);

      const color = d => getPartyColor(d.data.party);

      svg
        .attr("viewBox", [-width / 2, -width / 2, width, width])
        .style("font", "10px sans-serif");

      svg.append("g")
        .selectAll("path")
        .data(root.descendants())
        .enter()
        .append("path")
        .attr("d", arc)
        .style("fill", d => color(d))
        .style("stroke", "#fff")
        .on("mouseover", function (event, d) {
          d3.select(this).style("fill", d3.rgb(color(d)).brighter(0.5));
        })
        .on("mouseout", function (event, d) {
          d3.select(this).style("fill", color(d));
        })
        .on("click", (event, d) => {
          setSelectedNode(d.data); // Display detailed view on click
        });

      // Tooltips for hover
      svg.append("g")
        .selectAll("text")
        .data(root.descendants().filter(d => d.depth))
        .enter()
        .append("text")
        .attr("transform", function (d) {
          const angle = (d.x0 + d.x1) / 2 * 180 / Math.PI - 90;
          const rotate = `rotate(${angle}) translate(${(d.y0 + d.y1) / 2},0) rotate(${angle > 90 ? 180 : 0})`;
          return rotate;
        })
        .attr("dy", "0.35em")
        .attr("text-anchor", d => (d.x0 + d.x1) / 2 * 180 / Math.PI > 90 ? "end" : "start")
        .text(d => d.data.name);
    }
  }, [debateData, view]); // Depend on debateData and view so that it re-renders when either changes

  // Render ancestor stack tree layout using react-d3-tree
  const renderAncestorStackTree = () => {
    const nodeSize = { x: 300, y: 150 }; // Increase node spacing for better visibility

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <Tree
          data={debateData}
          orientation="vertical"
          nodeSize={nodeSize}
          separation={{ siblings: 1, nonSiblings: 1 }}
          pathFunc="elbow"
          translate={{ x: 500, y: 50 }}
          renderCustomNodeElement={(rd3tProps) => (
            <g>
              <rect
                width="250"
                height="80"
                rx="10" ry="10" // Rounded corners
                fill={getPartyColor(rd3tProps.nodeDatum.party)}
                stroke="#333"
              />
              <text
                fill="black"
                strokeWidth="1"
                x="125" // Center the text
                y="40"
                textAnchor="middle"
                alignmentBaseline="middle"
                fontSize="14px"
              >
                {rd3tProps.nodeDatum.name}
              </text>
            </g>
          )}
        />
      </div>
    );
  };

  // Handle debate switching
  const handleDebateChange = (debate) => {
    if (debate === 'debate1') {
      setDebateData(mockDebate1);
    } else if (debate === 'debate2') {
      setDebateData(mockDebate2);
    } else if (debate === 'debate3') {
      setDebateData(mockDebate3);
    }
  };

  // Toggle fullscreen mode for both views
  const handleFullScreen = () => {
    if (containerRef.current.requestFullscreen) {
      containerRef.current.requestFullscreen();
    } else if (containerRef.current.webkitRequestFullscreen) { /* Safari */
      containerRef.current.webkitRequestFullscreen();
    } else if (containerRef.current.msRequestFullscreen) { /* IE11 */
      containerRef.current.msRequestFullscreen();
    }
  };

  return (
    <div ref={containerRef} className="argument-container">
      {/* Debate Selection Buttons */}
      <div className="button-group">
        <button onClick={() => handleDebateChange('debate1')}>Debate 1: AGI</button>
        <button onClick={() => handleDebateChange('debate2')}>Debate 2: Social Media Regulation</button>
        <button onClick={() => handleDebateChange('debate3')}>Debate 3: Universal Basic Income</button>
        <button onClick={handleFullScreen}>Fullscreen</button>
      </div>

      {/* View Selection Buttons */}
      <div className="button-group">
        <button onClick={() => setView('sunburst')}>Sunburst View</button>
        <button onClick={() => setView('ancestorStack')}>Ancestor Stack Tree View</button>
      </div>

      {/* Render the selected view */}
      {view === 'sunburst' ? (
        <svg ref={svgRef} width="500" height="500"></svg>
      ) : (
        renderAncestorStackTree()
      )}

      {/* Detailed View */}
      {selectedNode && (
        <div className="details">
          <h2>{selectedNode.name}</h2>
          <p>{selectedNode.party === 'pro' ? 'Supporting Argument' : 'Opposing Argument'}</p>
          {selectedNode.children && selectedNode.children.map(child => (
            <div key={child.name}>
              <strong>{child.name}</strong>: {child.party}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ArgumentTypology;
