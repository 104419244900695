import { createSlice } from "@reduxjs/toolkit";
// here only the name of the roles, prime minister.... and it's id and ......

// Initial state for the slice
const initialState = {
  roleTemplates: [], // This will hold the role templates array
  status: "idle", // To track the loading status (idle, loading, succeeded, failed)
  error: null, // To track any errors
};

// Create the slice
const roleTemplatesSlice = createSlice({
    name: "roleTemplates",
    initialState,
    reducers: {
        setRoleTemplates(state, action) {
        // Set the role templates in the state
        state.roleTemplates = action.payload;
        },
        addRoleTemplate(state, action) {
        // Add a new role template to the state
        state.roleTemplates.push(action.payload);
        },
        removeRoleTemplate(state, action) {
        // Remove a role template by ID
        state.roleTemplates = state.roleTemplates.filter(
            (template) => template.id !== action.payload
        );
        },
        setError(state, action) {
        // Set an error message
        state.error = action.payload;
        },
        setStatus(state, action) {
        // Set the loading status
        state.status = action.payload;
        },
    },
});

// Export the actions generated by createSlice
export const {
    setRoleTemplates,
    addRoleTemplate,
    removeRoleTemplate,
    setError,
    setStatus,
} = roleTemplatesSlice.actions;

// Export the reducer to add to the store
export default roleTemplatesSlice.reducer;
