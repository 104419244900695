import React from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';

const FormatSelector = ({ updateFormat, formats }) => {
    const [showDropDown, setShowDropDown] = useState(false);
    const [selectedFormat, setSelectedFormat] = useState([]);

    const handleSelectingFormat = (format) => {
        updateFormat(format);
        setSelectedFormat(format)
        setShowDropDown(false);
    };

    const toggleDropDown = () => {
        setShowDropDown((oldState) => !oldState);
    };

    return (
        <>
            <div
                style={{ cursor: "pointer" }}
                className="info-item"
                onClick={toggleDropDown}
            >
                <i className="fa-solid fa-layer-group"></i>
                <div className="right-div">
                    <h3>Format</h3>
                    <p>
                        {selectedFormat.id
                            ? selectedFormat.name 
                            : "Choose the format for your debate."}
                    </p>
                </div>
            </div>
            <div style={{ position: 'relative', display: 'inline-block' }}>
                <AnimatePresence>
                    {showDropDown && (
                        <motion.div
                            className="dropdown"
                            initial={{ opacity: 0, maxHeight: 0, y: -20 }}
                            animate={{ opacity: 1, maxHeight: '400px', y: 0 }}
                            exit={{ opacity: 0, maxHeight: 0, y: -20 }}
                            transition={{ duration: 0.2, ease: 'easeInOut' }}
                        >
                            <ul>
                                {formats.map((format) => (
                                    <li
                                        key={format.id}
                                        onClick={() => handleSelectingFormat(format)}
                                    >
                                        {format.name}
                                    </li>
                                ))}
                            </ul>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </>
    )
}

export default FormatSelector;
