import React from "react";
import { useState } from "react";
import { TextField } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion"; // Import necessary components from framer-motion

// Helper function to format the date
const formatDate = (dateString) => {
  if (!dateString) return "Schedule the debate for later.";

  const date = new Date(dateString);

  const options = {
    weekday: "long", // Monday, Tuesday, etc.
    year: "numeric", // Full year
    month: "long", // Full month name
    day: "numeric", // Day of the month
    hour: "2-digit", // Hour in 2-digit format
    minute: "2-digit", // Minute in 2-digit format
  };

    return new Intl.DateTimeFormat("en-US", options).format(date);
};

const TimeSelector = ({ start_time, handleChange }) => {
    const [isTextFieldVisible, setTextFieldVisible] = useState(false);

    const handleStartTimeDivClick = () => {
        setTextFieldVisible((prevState) => !prevState);
    };

    const handleBlur = () => {
        // Delay the blur action to allow click events to be processed
        setTimeout(() => {
            setTextFieldVisible(false);
        }, 250);
    };

    return (
        <>
        <div
            style={{ cursor: "pointer" }}
            className="info-item"
            onClick={handleStartTimeDivClick}
        >
            <i className="fa-regular fa-clock"></i>
            <div className="right-div">
            <h3>Start time</h3>
            <p>{formatDate(start_time)}</p>
            </div>
        </div>

        <AnimatePresence>
            {isTextFieldVisible && (
            <motion.div
                initial={{ opacity: 0, y: -10 }} // Initial state for motion
                animate={{ opacity: 1, y: 0 }} // Animate to this state
                exit={{ opacity: 0, y: -10 }} // Exit state when removed
                transition={{ duration: 0.3 }} // Duration of the animation
            >
                <TextField
                label="Start Time"
                name="start_time"
                type="datetime-local"
                value={start_time}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                required
                autoFocus
                sx={{
                    marginTop: 2,
                    marginBottom:3,
                    backgroundColor: "#fff", // White background
                    borderRadius: 2, // Rounded corners
                    "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: "#ddd", // Light border color
                    },
                    "&:hover fieldset": {
                        borderColor: "#aaa", // Darker border on hover
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: "#333", // Darker border on focus
                    },
                    },
                    "& .MuiInputLabel-root": {
                    color: "#666", // Label color
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                    color: "#333", // Label color on focus
                    },
                }}
                />
            </motion.div>
            )}
        </AnimatePresence>
        </>
    );
    };

export default TimeSelector;
