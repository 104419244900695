import React, { useState, useEffect } from "react";
import heroImage from "../../assets/hero.png";
import hero3 from "../../assets/hero3.jpg";
import hero4 from "../../assets/hero4.jpg";
import hero5 from "../../assets/hero5.jpg";
import "./Hero.css";

const images = [heroImage, hero3, hero4, hero5]; // Array of images to cycle through

const Hero = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    // Effect to change the background image every 5 seconds
    useEffect(() => {
        const intervalId = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000); // Change image every 5 seconds

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, []);

    return (
        <div className="landing-page-div">
        <section
            className="hero-section"
            style={{
            backgroundImage: `linear-gradient(rgba(9,5,54,0.3), rgba(5,4,46,0.7)), url(${images[currentImageIndex]})`,
            }}
        >

            <h1 className="headerText">Debate Like a Pro</h1>
            <p className="text">
            AI-powered debates and tutorials for students and professionals. Get
            started for free.
            </p>
            <div className="search-container">
            <div className="search-input-wrapper">
                <i className="fa-solid fa-magnifying-glass search-icon"></i>
                <input
                type="text"
                className="search-input"
                placeholder="What do you want to debate?"
                />
                <button className="search-button">Search</button>
            </div>
            </div>
        </section>
        </div>
    );
};

export default Hero;
