    // src/App.js
    import React, { useState, useEffect } from "react";
    import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
    } from "react-router-dom";
    import axios from "axios";
    import Login from "./components/Login";
    import DebateList from "./components/DebateList";
    import DebateDetail from "./components/DebateDetail";
    import DebateMeeting from "./components/DebateMeeting";
    import Voting from "./components/Voting";
    import FactChecking from "./components/FactChecking";
    import CreateDebate from "./components/CreateDebate";
    import NavBar from "./components/NavBar";
    import ProfileUpdate from "./components/ProfileUpdate";
    import refreshAccessToken from "./components/TokenHandler";
    // import config from "./config";
    import LandingPage from "./components/LandingPage";
    import Register from "./components/Register";
    import DebateRoles from "./components/DebateRoles/DebateRoles";
import store from "./redux/store.js";
import { Provider } from "react-redux";


    const App = () => {
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [refreshToken, setRefreshToken] = useState(
        localStorage.getItem("refreshToken")
    );

    const handleSetToken = (access, refresh) => {
        setToken(access);
        setRefreshToken(refresh);
        localStorage.setItem("token", access);
        localStorage.setItem("refreshToken", refresh);
        console.log("Tokens set:", { access, refresh });
    };

    useEffect(() => {
        console.log("Initial refreshToken:", refreshToken);
        if (!refreshToken) {
        console.warn("No refresh token available");
        return;
        }

        const interval = setInterval(async () => {
        try {
            const newAccessToken = await refreshAccessToken(refreshToken);
            if (newAccessToken) {
            setToken(newAccessToken);
            localStorage.setItem("token", newAccessToken);
            console.log("Access token refreshed:", newAccessToken);
            } else {
            console.error("Failed to refresh access token");
            }
        } catch (error) {
            console.error("Error refreshing access token:", error);
        }
        }, 60 * 60 * 1000); // Refresh every 60 minutes

        return () => clearInterval(interval);
    }, [refreshToken]);

    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use((config) => {
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
        });

        const responseInterceptor = axios.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error.response && error.response.status === 401) {
            try {
                const newAccessToken = await refreshAccessToken(refreshToken);
                if (newAccessToken) {
                setToken(newAccessToken);
                localStorage.setItem("token", newAccessToken);
                error.config.headers[
                    "Authorization"
                ] = `Bearer ${newAccessToken}`;
                return axios(error.config);
                }
            } catch (e) {
                console.error("Error refreshing token on response:", e);
            }
            }
            return Promise.reject(error);
        }
        );

        return () => {
        axios.interceptors.request.eject(requestInterceptor);
        axios.interceptors.response.eject(responseInterceptor);
        };
    }, [token, refreshToken]);

    return (
        <Provider store={store}>
        <Router>
            <NavBar token={token} />
            <div className="App" style={{ paddingTop: "60px" }}>
                <Routes>
                <Route token={token} path="/" element={<LandingPage />} />
                <Route
                    path="/login"
                    element={
                    !token ? (
                        <Login setToken={handleSetToken} />
                    ) : (
                        <Navigate to="/dashboard" replace /> // Redirect to /dashboard if token is present
                    )
                    }
                />
                <Route
                    path="/dashboard"
                    element={<DebateList token={token} />} // Directly show DebateList when on /dashboard
                />

                <Route
                    path="/debate/:id"
                    element={
                    token ? <DebateDetail token={token} /> : <Navigate to="/" />
                    }
                />
                <Route
                    path="/debate/:id/meeting"
                    element={
                    token ? <DebateMeeting token={token} /> : <Navigate to="/" />
                    }
                />
                <Route
                    path="/debate/:id/vote"
                    element={token ? <Voting token={token} /> : <Navigate to="/" />}
                />
                <Route
                    path="/debate/:id/fact-check"
                    element={
                    token ? <FactChecking token={token} /> : <Navigate to="/" />
                    }
                />
                <Route
                    path="/create-debate"
                    element={
                    token ? <CreateDebate token={token} /> : <Navigate to="/" />
                    }
                />
                <Route
                    path="/profile"
                    element={
                    token ? <ProfileUpdate token={token} /> : <Navigate to="/" />
                    }
                />
                <Route path="/register" element={<Register />} />
                <Route
                    path="/debate-roles"
                    element={token?<DebateRoles token={token} /> :<Navigate to="/"/> }
                />
                </Routes>
            </div>
        </Router>
        </Provider>
    );
    };

    export default App;
