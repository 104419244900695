// src/components/Login.js
import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import config from "../config";
import "./Login.css";
import { setUserName } from "../redux/userSlice";
import { useDispatch } from "react-redux";

const Login = ({ setToken }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // State for managing errors
  const [isLoading, setIsLoading] = useState(false); // State for managing loading status
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateInputs = () => {
    if (!username.trim() && !password.trim()) {
      return "Both fields are required.";
    }
    if (!username.trim()) {
      return "Username cannot be empty or contain only spaces.";
    }
    if (!password.trim()) {
      return "Password cannot be empty or contain only spaces.";
    }
    return null;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const inputError = validateInputs();
    if (inputError) {
      setError(inputError);
      return;
    }

    setIsLoading(true); // Start loading

    try {
      const response = await axios.post(`${config.apiUrl}/token/`, {
        username: username.trim(),
        password: password.trim(),
      });
      const { access, refresh } = response.data;
      console.log("login, username=" + username);
      dispatch(setUserName(username.trim()));
      localStorage.setItem("username", username.trim());
      setToken(access, refresh);
      navigate("/");
    } catch (error) {
      console.error("Error during login:", error);
      if (error.response && error.response.status === 401) {
        const errorMessage =
          password.trim() === ""
            ? "Password is required."
            : "Invalid username or password. Please check your credentials.";
        setError(errorMessage);
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    if (error) {
      setError(""); // Clear error when user starts typing
    }
  };

  return (
    <motion.div
      className="login-container"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h1>Welcome to RgueAI</h1>

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          className="floating-input"
          placeholder="Username"
          value={username}
          onChange={handleInputChange(setUsername)}
        />
        <input
          type="password"
          className="floating-input"
          placeholder="Password"
          value={password}
          onChange={handleInputChange(setPassword)}
        />
        <button type="submit" disabled={isLoading}>
          Login
        </button>

        {/* Display error message */}
        {error && <div className="error-message">{error}</div>}
      </form>

      {/* Show loading indicator */}
      {isLoading && (
        <div className="loading-spinner-login">
          <div className="spinner-login"></div>
          {/* <p>Loading...</p> */}
        </div>
      )}

      {/* FORGOT PASSWORD LINK */}
      <div className="login-button-div">
        <Link to="/forgot-password">Forgot Your password?</Link>
      </div>
      <div className="s-buttons-div">
        <Link to="/google-login">
          <i className="fa-brands fa-google"></i> Continue with Google
        </Link>
        <Link to="/facebook-login">
          <i className="fa-brands fa-facebook-f"></i> Continue with Facebook
        </Link>
      </div>
      <div
        style={{
          color: "#4f7396",
          fontFamily: "Inter",
          fontSize: "12px",
          textAlign: "center",
        }}
      >
        Or you're new here?
      </div>
      <div className="buttons-div">
        <Link to="/register">Register</Link>
      </div>
    </motion.div>
  );
};

export default Login;
