import { createSlice } from "@reduxjs/toolkit";
// to save the changed role of the user
const initialState = {
  currentRole: null, // This should match with the reducer's logic
  mightSelectedRole: null,
  status: "idle",
  isDialogOpen: false, // State to manage dialog visibility
};
const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.currentRole = action.payload;
      state.status = "assigned";
    },
    openDialog: (state, action) => {
      state.isDialogOpen = true; // Open the dialog
      state.mightSelectedRole = action.payload;
      console.log("openDialog called - isDialogOpen:", state.isDialogOpen);
    },
    closeDialog: (state) => {
      state.isDialogOpen = false;
      state.mightSelectedRole = null;
      console.log("closeDialog called - isDialogOpen:", state.isDialogOpen);
    },
  },
});


export const { setRole, resetStatus, closeDialog, openDialog, resetRole } = roleSlice.actions;
export default roleSlice.reducer;
