import { createSlice } from "@reduxjs/toolkit";

// Initial state for user information
const initialState = {
    name: null,
    role: null,
    mightSelectedRole:null,
    isDialogOpen: false, // State to manage dialog visibility
};

// Create the user slice
const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserName(state, action) {
        state.name = action.payload;
        },
        setUserRole(state, action) {
        state.role = action.payload;
        },
        openDialog: (state, action) => {
        state.isDialogOpen = true; // Open the dialog
        state.mightSelectedRole = action.payload;
        },
        closeDialog: (state) => {
            state.isDialogOpen = false;
            state.mightSelectedRole = null;
        },
    },
});

// Export the actions
export const { setUserName, setUserRole,openDialog,closeDialog } = userSlice.actions;

// Export the reducer to add to the store
export default userSlice.reducer;
