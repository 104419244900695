import React from 'react'
import hero from '../../assets/hero.png';
import {formatDate} from "../../data/static";
import "./Hero.css";
const Hero = ({debate}) => {
    let startDate, startTime;
    // let endDate, endTime;
    if (debate.start_time) {
        [startDate, startTime] = formatDate(debate.start_time).split(" at ");
    }
    // if (debate.end_time) {
    //     [endDate, endTime] = formatDate(debate.end_time).split(" at ");
    // }
    return (
        <div className='hero-div'>
                <div className='debate-img-div'>
                {debate.image? <img style={{borderRadius:'12px'}} src={debate.image} alt="debate"></img>:<img src={hero} alt=''></img>}
                {/* if no image, don't forget to handle it */}
                </div>

            <div className='debate-details-div'>
                <div className='debate-topic'>
                    <h4>{debate.topic}</h4>
                </div>
                
                <div className='debate-motion'>
                    <h5>{debate.motion? debate.motion:'This House believes that Palestine should be recognized as an independent country.'}</h5>
                </div>
                <hr className='light-line' />

                
                <div className='debate-start-time'>

                    <div> 
                        <span >Start Time</span>
                        <div style={{marginTop:'10px'}}>
                            <div style={{display:'flex', gap:'15px', alignItems:'center'}}>
                                <i className="fa-solid fa-calendar-days"></i>
                                {startDate}
                            </div>
                            <div style={{display:'flex', gap:'15px', alignItems:'center', marginTop:'10px'}}>
                                <i className="fa-regular fa-clock"></i>
                                {startTime}
                            </div>       
                        </div>
                    </div>
                    
                </div>

                <hr className='light-line' />


                {/* <hr className='light-line' /> */}
                <div className='status-div' style={{display:'flex', flexDirection:'column'}}>
                    <p style={{marginBottom:'10px', fontSize:'18px'}}>status</p>
                    <span style={{color:'#3D4D5C'}}>{debate.status}</span>
                </div>
            </div>
            </div>
    )
}

export default Hero