    import React, { useState, useEffect } from "react";
    import { Avatar, IconButton } from "@mui/material";
    import { Link, useNavigate } from "react-router-dom";
    import axios from "axios";
    import config from "../config";
    import LogoutIcon from "@mui/icons-material/Logout";
    import "./NavBar.css";
    import { useLocation } from "react-router-dom";
    import { useDispatch } from "react-redux";
    import { setUserName } from "../redux/userSlice";


    const NavBar = ({ token, setToken }) => {
    const [profile, setProfile] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const currentPath = location.pathname;


    useEffect(() => {
        if (token) {
        const fetchProfile = async () => {
            try {
            const response = await axios.get(`${config.apiUrl}/profiles/me/`, {
                headers: {
                Authorization: `Bearer ${token}`,
                },
            });
            setProfile(response.data);
            } catch (error) {
            console.error("Error fetching profile:", error);
            }
        };
        fetchProfile();
        }
    }, [token]);

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("username");
        dispatch(setUserName(null));
        navigate("/");
        window.location.reload();
    };
    return (
        <nav>
        <div className="left-nav">
            <Link to="/" className="logo-link">
            <svg
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="logo"
            >
                <path
                d="M4 4H17.3334V17.3334H30.6666V30.6666H44V44H4V4Z"
                fill="currentColor"
                ></path>
            </svg>
            <h1 style={{ fontSize: "18px", marginLeft: "10px" }}>RgueAI</h1>
            </Link>
        </div>
        <div className="right-nav">
            <div className="links-div">
            <ul className="links">
                <li>
                <Link to="/">Home</Link>
                </li>
                <li>
                    {token ? <Link to="/dashboard">Dashboard</Link>:<></>}
                </li>
            </ul>
            </div>


            <div className="nav-buttons">
            {profile ? (
                <>
                <Link to="/profile">
                    <Avatar
                    alt="Profile Picture"
                    src={profile.profile_picture || ""}
                    className="profileAvatar"
                    />
                </Link>
                <IconButton onClick={handleLogout} className="logoutButton">
                    <LogoutIcon />
                </IconButton>
                </>
            ) : (
                <>
                {/* if in login or regiter route, hide */}
                    {currentPath === '/login' ? <></>: <> <Link className="login-button" to='/login'>Login</Link>
                    <Link className="signup-button" to='/register'>Signup</Link></> }
                </>
            )}
            </div>
        </div>
        </nav>
    );
    };

    export default NavBar;
