import React from "react";
import "./ProgressBar.css"; // Custom styling

const ProgressBar = ({ step, totalSteps }) => {
  const progressPercentage = (step / totalSteps) * 100;

  return (
    <div className="progress-bar-container">
      <h2>Create a New Debate</h2>
      <div className="progress-bar-background">
        <div
          className="progress-bar-foreground"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
      <p>{step}/{totalSteps} steps</p>
    </div>
  );
};

export default ProgressBar;
