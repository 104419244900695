import React from "react";
import { motion, useInView } from "framer-motion";
import { sections } from "../../data/static";
import "./AboutUs.css";

const AboutUs = () => {
  // Animation variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.2, // Delays each child element for a staggered effect
        },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 0.5 },
        },
    };

    // Use useRef and useInView for scroll-triggered animations
    const ref = React.useRef(null);
    const isInView = useInView(ref, { once: true, margin: "-100px" });

    return (
        <motion.section
        className="about-us-section"
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={containerVariants}
        >
        <motion.h1 className="about-us-heading" variants={itemVariants}>
            Rgue.ai for Students and Universities
        </motion.h1>
        <motion.p className="about-us-description" variants={itemVariants}>
            Rgue.ai is the leading platform for AI-powered debates and educational
            content. Our AI tutor can help you improve your debating skills, and our
            platform hosts tournaments and leagues for students and university
            debate teams.
        </motion.p>

        <motion.div
            className="section-list"
            variants={containerVariants}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
        >
            {sections.map((section, index) => (
            <motion.div
                key={index}
                className="section-item"
                variants={itemVariants}
                whileHover={{ scale: 1.05 }} // Slightly enlarges the section item on hover
            >
                <motion.img
                src={section.url}
                alt={section.title}
                className="section-image"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                />
                <motion.h2
                style={{ fontSize: "16px" }}
                className="section-title"
                variants={itemVariants}
                >
                {section.title}
                </motion.h2>
                <motion.p
                style={{ color: "#61788A", margin: "5px", textAlign: "left" }}
                className="section-description"
                variants={itemVariants}
                >
                {section.description}
                </motion.p>
            </motion.div>
            ))}
        </motion.div>
        </motion.section>
    );
};

export default AboutUs;
