import React from "react";

const SearchBar = () => {
    return (
        <div className="search-bar">
        <input type="text" placeholder="Search debates" />
        </div>
    );
};

export default SearchBar;
